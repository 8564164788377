import {Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { InputMaskType } from '@rogers/cdk/form';
import IMask from 'imask/esm/imask';
import {AccordionPanel} from '@rogers/cdk/accordion';
import {
  ModalComponent as DsModalComponent,
  ModalRef,
  ModalService,
} from '@rogers/cdk/modal';
import { ReCaptchaBadge, RecaptchaComponent, RecaptchaLoaderService } from '@rogers/ng-recaptcha';
import { ConfigurationService } from '../shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { delay, filter, map, take } from 'rxjs/operators';
import { UserMonitoringService } from 'src/app/shared/user-monitoring.service';
import { LoadRecaptchaSitekeyService } from '../shared/load-recaptcha-site-key-service';
import { UserSessionRiskService } from '../shared/user-session-risk-service';

@Component({
    templateUrl: './usernameFormSession.component.html',
    styleUrls: ['./usernameFormSession.component.scss']
})
export class UsernameFormSessionComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly recaptchaLoaderService: RecaptchaLoaderService,
    private readonly configurationService: ConfigurationService,
    private readonly cookieService: CookieService,
    private readonly usm: UserMonitoringService,
    private readonly loadRecaptchaSiteKey: LoadRecaptchaSitekeyService,
    private readonly userSessionRiskService: UserSessionRiskService
  ) {

    const client =  this.cookieService.get('client');
    const siteKeyClient = (client === 'ignitetv') ? 'rogers' : client;

    this.reCaptchaSiteKey = this.loadRecaptchaSiteKey.getKey('V2', siteKeyClient);
    this.reCaptchaV3SiteKey = this.loadRecaptchaSiteKey.getKey('V3', siteKeyClient);

    const flow = this.cookieService.get('flow');
    if (flow === 'username'){
      this.loginStore.flowBranch = this.flowBranchEnum.username;
    }
    else{
      this.loginStore.flowBranch = this.flowBranchEnum.login;
    }

    this.cookieService.delete('flow');
    console.log('enum value from function: ', this.getEnumKeyByEnumValue(this.flowBranchEnum, 'create'));
  }
  payload: object;
  flowFromPayload: string;

  onSubmitUserId: (payload: object) => void;
  onForgotUserEmailSubmit: (payload: object) => void;
  onRegisterClick: (code: string) => void;
  onForgotUsernameAccountClick: () => void;
  onVerifyUserEmailSubmit: (payload: object) => void;

  readonly flowBranchEnum = FlowBranchEnum;
  loginStore = new LoginStoreModel();
  rememberMeFlagValue = false;
  rememberMeUser: string;
  @ViewChild('username') usernameField: ElementRef;
  @ViewChild('emailUsername') emailUsernameField: ElementRef;
  @ViewChild('emailCreate') emailCreateField: ElementRef;
  @ViewChild('submitLogin') submitLoginButton: ElementRef;
  @ViewChild('submitCreate') submitCreateButton: ElementRef;
  @ViewChild('submitUsername') submitUsernameButton: ElementRef;
  @ViewChild('submitProfile') submitProfileButton: ElementRef;
  @ViewChild('account') accountField: ElementRef;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
  @ViewChild('captchaRefV3')
  captchaRefV3: RecaptchaComponent;
  @ViewChild('changeusernameModal') public templateRef: TemplateRef<DsModalComponent>;
  @ViewChild('usernameInput', { static: true })

  currentLang: string;
  dobMask: any;
  readonly inputMaskType = InputMaskType;
  submitted: boolean;

  // reCaptcha
  recaptcha: string;
  recaptchaBadge: ReCaptchaBadge = 'bottomright';
  recaptchaClass = 'recaptcha-non-ie';
  reCaptchaSiteKey: string;
  reCaptchaV3SiteKey: string;
  useRecaptchaV3 = true;
  blockRecaptcha = false;
  loginStart = false;
  recaptchaTriggered = false;
  reCaptchaVersion = 'V3';
  loginForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  headerHolder: string;
  footerHolder: string;
  footerRegister: string;
  loginTip: string;
  recoverUsernameTip: string;
  client: string | null;
  userEmail: string;
  apiError = false;
  forgotUsernameByEmailDesc: string;
  accessibilityContinue: string;
  registerAttr: string;
  usernameLabel: string;

  usernameAttr: string;
  usernameTitle: string;

  rememberMeTitle: string;
  rememberMeAttr: string;

  hideForgotUsername = false;
  footerRegisterLink: string;
  footerRegisterHeader: string;
  donotRememberTitle: string;
  igniteTv: string;
  signInHeader: string;
  igniteUserSelectedBrand: string;
  showIgniteTvRogersAccountLink: boolean;
  igniteTvRogersAccountLink: string;

  registerForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('' , [
        Validators.required,
        Validators.email
      ])
    },
    { updateOn: 'submit' }
  );

  accountNumberMask = '000000000000';
  accountNumberRegex = '^(?=[0-9]*$)(?:.{9}|.{12})$';
  postalCodeRegex = '^[A-Za-z]\\d[A-Za-z]\\d[A-Za-z]\\d$';
  dateRegex = '^\\d{4}\\/\\d{2}\\/\\d{2}$';

  modalRef: ModalRef;
  accountModalAccordion: Array<AccordionPanel>;

  accountForm = new UntypedFormGroup(
    {
      accountNumber: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.accountNumberRegex)
      ]),
      postalCode: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.postalCodeRegex)
      ]),
      dob: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.dateRegex)
      ])
    }
  );

  color = 'white';
  variant = 'regular';
  size = 'md';
  brand1 = 'rogers';
  loginInProgress = false;
  verifyEmailInProgress = false;
  usernameInProgress = false;

  track = {
    loginUsernameInputPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'username-input',
      additionalPayload: {
        miscellaneous: {
          type: 'username-remembered',
          name: this.cookieService.get('rememberMe')  ? 'yes' : 'no'
        }
      }
    },
    usernameInputErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: '',
      category: '',
      type: 'http',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    loginUsernameInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'username-input',
      category: 'login',
      type: 'form',
      code: 'ECA1',
      API: '',
      field: 'username'
    },
    findUsernameWithEmailPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'find-username-with-email'
    },
    recoveryUsernameInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'find-username-with-email',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'username'
    },
  };

  private static isEnglishOrFrench(languageToCheck) {
    const path = window.location.href;
    if (
      path.indexOf(`/${languageToCheck}`) > -1 ||
      path.indexOf(`lang=${languageToCheck}`) > -1 ||
      path.indexOf(`setLanguage=${languageToCheck}`) > -1
    ) {
      return languageToCheck;
    }
    return '';
  }

  private static determineLanguage() {
    let language = UsernameFormSessionComponent.isEnglishOrFrench('en');
    if (!language) {
      language = UsernameFormSessionComponent.isEnglishOrFrench('fr');
    }
    language = language || 'en';
    return language;
  }

  ngOnInit() {
    this.client = this.cookieService.get('client');

    this.igniteUserSelectedBrand = this.cookieService.get('ignite-tv-selection');

    const useRecaptcha = this.cookieService.get('useRecaptchaV3');
    const version = this.cookieService.get('reCaptchaVersion');

    this.useRecaptchaV3 = useRecaptcha === 'false' ? false : true;
    this.reCaptchaVersion = version ? version : this.reCaptchaVersion;

    this.loginStart = true;
    this.cookieService.set('analytics', '',undefined, undefined, null, true, 'None');
    const element = document.getElementById('transmitContainer');
    element.classList.add('hideTransmitContainer');

    this.setTranslatePropertiesByBrand();

    if (this.client === 'r4b' || this.client === 'yahoo' || this.client === 'media'){
      this.hideForgotUsername = true;
    }
    this.currentLang = this.cookieService.get('lang');
    console.log('currentLang: ', this.currentLang);


    console.log('flowFromPayload: ', this.flowFromPayload);
    console.log('loginStoreErrorCode: ', this.cookieService.get('loginStoreErrorCode'));

    this.loginStore.errorCode = this.cookieService.get('loginStoreErrorCode');
    this.cookieService.set('loginStoreErrorCode', '',undefined, undefined, null, true, 'None');

    if (this.loginStore.errorCode === 'UE05'){
      this.loginStore.flowBranch = this.flowBranchEnum.login;
      this.userEmail = this.cookieService.get('userEmail');
    }

    if (this.loginStore.errorCode === 'UE01'){
      this.userEmail = this.cookieService.get('userEmail');
    }

    this.dobMask = {
      blocks: {
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          minLength: 2,
          maxLength: 2,
          placeholderChar: 'M'
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          minLength: 2,
          maxLength: 2,
          placeholderChar: this.currentLang === 'en' ? 'D' : 'J'
        },
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: new Date().getFullYear(),
          minLength: 4,
          maxLength: 4,
          placeholderChar: this.currentLang === 'en' ? 'Y' : 'A'
        }
      },
      mask: 'YYYY/MM/DD'
    } as any;

    this.accountModalAccordion = [
      {
        header: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReady'
        ),
        expandedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReadyExpandedAlt'
        ),
        collapsedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReadyCollapsedAlt'
        ),
        expanded: false
      },
      {
        header: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreement'
        ),
        expandedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreementExpandedAlt'
        ),
        collapsedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreementCollapsedAlt'
        ),
        expanded: false
      }
    ];

    console.log('oninit called');

    this.setTitle();

    this.creatForgotUsernameForm();
    this.createUsernameForm();

    this.setAnalyticsError(
      this.loginStore.errorCode,
      this.loginStore.flowBranch
    );

    setTimeout(() => {
      if (this.loginStore.flowBranch === this.flowBranchEnum.username){
        this.emailUsernameField.nativeElement.focus();
        const forgotUsername = this.cookieService.get('forgot-username');
        this.emailForm.controls['email'].setValue(forgotUsername);
      }
      else{
        const loginHintEmail = this.cookieService.get('login-hint-email');
        if (!this.isloginHintEmail(loginHintEmail)){
          this.loginForm.get('username').reset(this.rememberMeUser);
        }

        this.loginForm.get('rememberMe').reset(this.rememberMeFlagValue);
        if (this.loginStore.errorCode === 'UE01'){
          const username =  this.cookieService.get('username');
          if (username){
            this.loginForm.controls['username'].setValue(username);
          }
        }
        else if (this.loginStore.errorCode !== 'UE05'){
          this.usernameField.nativeElement.focus();
        }
        if (!this.useRecaptchaV3 && this.reCaptchaVersion === 'V2'){
          const username = this.cookieService.get('userEmail-username');
          this.loginForm.controls['username'].setValue(username);
        }
      }

    }, 500);
  }


  setTitle() {
    let title = '';
    let brand = this.cookieService.get('brand');
    const client = this.cookieService.get('client');

    if (client === 'media'){
      brand = client;
    }
    if (this.loginStore.flowBranch === this.flowBranchEnum.login && this.loginStore.errorCode !== 'UE05')
    {
      title = brand + '.login.browserTitle';
      if(client === 'yahoo'){
        title = client + '.login.browserTitle';
        }

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.login && this.loginStore.errorCode === 'UE05')
    {
      title = brand + '.forgotUsername.browserTitle';

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.create)
    {
      title = brand + '.login.browserTitle';

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.username)
    {
      title = brand + '.forgotUsername.browserTitle';

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.profile)
    {
      title = brand + '.forgotUsername.browserTitle';

    }
    else {
      title = brand + '.login.browserTitle';
    }

    this.titleService.setTitle(
      this.translate.instant(title)
    );

    this.translate.onLangChange
      .subscribe((langEvent: LangChangeEvent) => {
        this.titleService.setTitle(
          this.translate.instant(title)
        );
        this.currentLang = langEvent.lang;
      });
  }


  createUsernameForm(){
    const loginHintEmail = this.cookieService.get('login-hint-email');
    const rememberFlag = this.cookieService.get('rememberMe');
    this.rememberMeUser = this.cookieService.get('username'); // env50@mailinator.com
    this.rememberMeFlagValue = !(rememberFlag === 'false');

    let userEmail = '';
    if  (this.isloginHintEmail(loginHintEmail)){
      userEmail = loginHintEmail;
    }
    else{
      userEmail = this.rememberMeUser;
    }

    this.loginForm = new UntypedFormGroup(
      {
        username: new UntypedFormControl(userEmail, [Validators.required]),
        rememberMe: new UntypedFormControl(this.rememberMeFlagValue, [])
      },
      { updateOn: 'submit' }
    );
  }

  creatForgotUsernameForm(){
    const emailRegex = this.configurationService.config.emailValidationPattern;
    this.emailForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl('' , [Validators.required, Validators.pattern(emailRegex)])
      },
      { updateOn: 'submit' }
    );
  }

  validateAndSubmit() {
    // set uu username on dynatrace
    this.usm.identifyUser(this.loginForm.value.username);

    this.submitted = true;
    this.resetErrorCode();

    if (this.blockRecaptcha) {
      // Re-captcha is blocked to facilitate PROD PAT
      // this.recaptcha = this.petCaptchaKey;
      if (this.loginStore.flowBranch === this.flowBranchEnum.username){
        this.onForgotUsernameSubmit();
      }
      else{
        this.postSubmit();
      }
    }
    else {
      // if Recaptcha is not blocked, i.e BAU flow
      this.triggerRecaptcha();
    }
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    switch (this.loginStore.flowBranch) {
      case this.flowBranchEnum.login: {
        this.submitLoginButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.username: {
        this.submitUsernameButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.create: {
        this.submitCreateButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.profile: {
        this.submitProfileButton.nativeElement.click();
        break;
      }
    }

  }

  resetErrorCode() {
    this.loginStore.errorCode = '';
  }


  routeLink(flow: FlowBranchEnum) {
    this.resetErrorCode();
    if ((this.client  === 'ignitetv' || 'shaw') && this.igniteUserSelectedBrand === 'shaw'){
      this.onForgotUsernameAccountClick();
    }
    else{
    this.loginStore.flowBranch = flow;
    setTimeout(() => {
      switch (this.loginStore.flowBranch) {
        case this.flowBranchEnum.login: {
          this.emailForm.reset();
          this.usernameField.nativeElement.focus();
          this.cookieService.delete('flow');
          this.setTitle();
          break;
        }
        case this.flowBranchEnum.username: {
          this.emailForm.reset();
          this.cookieService.set('title', 'forgotusername',undefined, undefined, null, true, 'None');
          this.emailUsernameField.nativeElement.focus();
          this.setTitle();

          break;
        }
        case this.flowBranchEnum.create: {
          this.emailCreateField.nativeElement.focus();
          break;
        }
        case this.flowBranchEnum.profile: {
          this.accountField.nativeElement.focus();
          break;
        }
      }
    }, 500);
   }
 }

  onPrepaid(){
    window.location.href = 'https://www.rogers.com/web/totes/#/sso/registration';
  }

  openModal(templateRef: TemplateRef<DsModalComponent>) {
    this.modalRef = this.modalService.open(
      templateRef,
      this.viewContainerRef,
      { backdropClick: false }
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  modalCloseButtonClick(event: Event) {
    // console.log('Modal close button clicked', event);
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  // recaptcha section
  processRecaptcha(recaptcha: string) {
    this.cookieService.delete('useRecaptchaV3');
    this.cookieService.delete('reCaptchaVersion');
    this.cookieService.delete('userEmail-username');
    this.cookieService.delete('forgot-username');

    this.recaptcha = recaptcha;
    this.recaptchaTriggered = false;
    const isVerifyEmail =  this.cookieService.get('verifyEmail');

    if (isVerifyEmail === 'true'){
      const email = this.cookieService.get('userEmail');
      this.triggerRiskAction('verify-pending-email', email);

      this.verifyEmailInProgress = true;
      console.log('verify email remember me : ', this.loginForm.value.rememberMe);
      this.onVerifyUserEmailSubmit({
        action: 'submit',
        username: email,
        flow: 'create',
        recaptachaToken: this.recaptcha,
        siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
        language: this.cookieService.get('lang'),
        rememberme: this.cookieService.get('rememberMe'),
        version: this.reCaptchaVersion
      });
    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.username){
      this.onForgotUsernameSubmit();
    }
    else{
      this.postSubmit();
    }
  }


  private checkInProgress() {
    this.loginInProgress = true;

  }


  private checkTransmitContainerError () {
    let transmitContainerHTML = document.getElementById("transmitContainer").innerHTML;
    let str = 'Error';
    let errorFound = transmitContainerHTML.includes(str);
    console.log('transmitContainerHTML ', transmitContainerHTML);


    if (transmitContainerHTML.indexOf(str) !== -1)
    {
      console.error('Transmit Error');
      let continueBtn: HTMLElement= document.querySelectorAll("button.xmui-button")[0] as HTMLElement;
      //console.error('continueBtn ', continueBtn);
      continueBtn.click();
    }
  }

  //  check if we have locked user dialog box in dom 

  private checkUserLocked() {
    const transmitContainerHTML = document.getElementById('transmitContainer').innerHTML;
    const str = 'User Locked';
    const lockedFound = transmitContainerHTML.includes(str);
    // console.log('transmitContainerHTML ', transmitContainerHTML);

    if (transmitContainerHTML.indexOf(str) !== -1){
      console.error('User is locked by Transmit');
      let continueBtn: HTMLElement= document.querySelectorAll("button.xmui-button")[0] as HTMLElement;
      continueBtn.click();
    }
    else {
      console.error('User is NOT locked by Transmit');
      this.loginInProgress = false;

    }
  }


  private postSubmit() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    var element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    setTimeout(this.checkTransmitContainerError, 1500);
    setTimeout(this.checkUserLocked, 1600);

    if (this.recaptcha && username !== null && password !== null) {
    if (!this.loginForm.invalid) {

      this.triggerRiskAction('username', this.loginForm.value.username);
      this.loginInProgress = true;

      if (this.loginForm.value.rememberMe) {
        this.cookieService.set('username', this.loginForm.value.username, undefined, undefined, null, true, 'None');
        this.cookieService.set('rememberMe', this.loginForm.value.rememberMe, undefined, undefined, null, true, 'None');
      } else {
        this.cookieService.delete('username');
        this.cookieService.delete('rememberMe');
        this.rememberMeFlagValue = false;
      }

      this.cookieService.set('userEmail', this.loginForm.value.username,undefined, undefined, null, true, 'None');

      console.log('remember me : ', this.loginForm.value.rememberMe);
      this.onSubmitUserId({
        action: 'submit',
        username: this.loginForm.value.username,
        flow: 'login',
        recaptachaToken: this.recaptcha,
        siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
        language: this.cookieService.get('lang'),
        rememberme: this.loginForm.value.rememberMe,
        version: this.reCaptchaVersion
      });
    } else {
      console.log('validateAndSubmit - form is invalid');
      return;
     }
    }
    else {
      this.validateAndSubmit();
    }
  }

  private onForgotUsernameSubmit() {

    const userEmail = this.emailForm.value.email;
    this.triggerRiskAction('forgot-username', userEmail);
    this.cookieService.set('userEmail', userEmail, undefined, undefined, null, true, 'None');

    if (userEmail !== null) {
    if (!this.emailForm.invalid) {

      this.usernameInProgress = true;
      this.onForgotUserEmailSubmit({
        action: 'submit',
        username: this.emailForm.value.email,
        flow: 'password',
        recaptachaToken: this.recaptcha,
        siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
        language: this.cookieService.get('lang'),
        version: this.reCaptchaVersion
      });
    }
    else {
      console.log('forgot username email form is invalid');
      return;
     }
    }
  }

  onVerifyEmail() {
    this.triggerRecaptcha();
    this.cookieService.set('verifyEmail', 'true',undefined, undefined, null, true, 'None');

    // check transmit hidden errors in dom
    var element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    setTimeout(this.checkTransmitContainerError, 1500);
    setTimeout(this.checkUserLocked, 1600);
    //
  }


  setErrorCode () {
    this.loginInProgress = false;
    this.apiError = true;
  }

  private triggerRecaptcha() {
    this.recaptchaLoaderService.ready
      .pipe(
        filter(val => val !== null),
        take(1),
        map(val => !!val),
        delay(350)
      )
      .subscribe(recaptchaIsReady => {
        if (recaptchaIsReady) {
          if (!this.loginInProgress) {
            // this.loginInProgress = true;
            this.recaptchaTriggered = true;
            if (this.useRecaptchaV3 && !!this.captchaRefV3) {
              this.captchaRefV3.execute();
              this.reCaptchaVersion = 'V3';
            } else if (this.captchaRef) {
              this.captchaRef.execute();
              this.reCaptchaVersion = 'V2';
            }
          } else if (
            this.loginInProgress &&
            this.recaptchaTriggered &&
            !!this.captchaRef
          ) {
            this.captchaRef.execute();
            this.reCaptchaVersion = 'V2';
          }
        }
      });
  }

   resetRecaptcha() {
    if (!this.blockRecaptcha) {
      this.recaptcha = '';
      if (this.useRecaptchaV3 && this.captchaRefV3) {
        this.captchaRefV3.reset();
      } else if (!this.useRecaptchaV3 && this.captchaRef) {
        this.captchaRef.reset();
      }
    }
  }

  outsideClickCapture() {
    this.loginStart = false;
  }

  onRegisterClickSubmit(){
    const brand = this.cookieService.get('brand');
    this.cookieService.set('title', 'register', undefined, undefined, null, true, 'None');

    this.onRegisterClick(brand);
  }

  onForgotUsernameAccountClickSubmit(){
    this.onForgotUsernameAccountClick();
  }

  private setTranslatePropertiesByBrand(){
    this.igniteTv  = this.cookieService.get('igniteBrand');
    this.client = this.cookieService.get('client');

    // common
    this.footerRegister = 'rogers.login.register';
    this.loginTip = 'rogers.login.tip';
    this.recoverUsernameTip = 'rogers.login.recoverUsernameTip';
    this.accessibilityContinue = 'rogers.login.accessibility.continue';
    this.registerAttr = 'rogers.login.registerAttr';
    this.rememberMeTitle = 'rogers.login.rememberMe';
    this.rememberMeAttr = 'rogers.login.rememberMeAttr';
    this.usernameAttr = 'rogers.login.usernameAttr';
    this.usernameTitle = 'rogers.login.username';
    this.usernameLabel = 'rogers.login.username';
    this.headerHolder = 'rogers.login.header';
    this.footerHolder = 'rogers.login.footer';
    this.signInHeader = 'common.signin';


    // Overrides below
    if (this.client === 'media'){
      this.headerHolder = 'media.login.header';
      this.footerHolder = 'media.login.footer';
      this.forgotUsernameByEmailDesc = 'media.forgotUsername.description.byEmail';
      this.accessibilityContinue = 'media.login.accessibility.continue';
      this.registerAttr = 'media.login.registerAttr';
      this.usernameLabel = 'media.login.username';
      this.usernameAttr = 'media.login.usernameAttr';
      this.usernameTitle = 'media.login.username';
      this.rememberMeTitle = 'media.login.rememberMe';
      this.rememberMeAttr = 'media.login.rememberMeAttr';
      this.loginTip = 'media.login.tip';
    }
    else if (this.client === 'fido') {
      this.headerHolder = 'fido.login.header';
      this.footerHolder = 'fido.login.footer';
      this.forgotUsernameByEmailDesc = 'fido.forgotUsername.description.byEmail';
      this.loginTip = 'fido.login.tip';
      this.recoverUsernameTip = 'fido.login.recoverUsernameTip';
      this.accessibilityContinue = 'fido.login.accessibility.continue';
      this.registerAttr = 'fido.login.registerAttr';

    }
    else if (this.client === 'r4b') {
      this.headerHolder = 'r4b.login.header';
      this.footerHolder = 'r4b.login.footer';
      this.forgotUsernameByEmailDesc = 'rogers.forgotUsername.description.byEmail';
      this.footerRegister = 'r4b.login.register';
      this.loginTip = 'r4b.login.tip';
      this.recoverUsernameTip = 'r4b.login.recoverUsernameTip';
      this.accessibilityContinue = 'r4b.login.accessibility.continue';
      this.registerAttr = 'r4b.login.registerAttr';

    }
    else if (this.client === 'yahoo') {
      this.headerHolder = 'yahoo.login.header';
      this.footerHolder = 'yahoo.login.footer';
      this.forgotUsernameByEmailDesc = 'yahoo.forgotUsername.description.byEmail';
      this.footerRegister = 'yahoo.login.register';
      this.loginTip = 'yahoo.login.tip';
      this.recoverUsernameTip = 'yahoo.login.recoverUsernameTip';
      this.accessibilityContinue = 'yahoo.login.accessibility.continue';
      this.registerAttr = 'yahoo.login.registerAttr';

    }

    else if (this.client === 'cpp' || this.client === 'chatr') {
      this.headerHolder = 'chatr.login.header';
      this.footerHolder = 'chatr.login.footer';
      this.forgotUsernameByEmailDesc = 'chatr.forgotUsername.description.byEmail';
      this.footerRegister = 'chatr.login.register';
      this.loginTip = 'chatr.login.tip';
      this.recoverUsernameTip = 'chatr.login.recoverUsernameTip';
      this.accessibilityContinue = 'chatr.login.accessibility.continue';
      this.registerAttr = 'chatr.login.registerAttr';

    }
    else if (this.client  === 'ignitetv' || this.client === 'shaw') {
      this.showIgniteTvRogersAccountLink = false;

      if (this.igniteUserSelectedBrand === 'rogers'){
        this.headerHolder = 'rogersShaw.rogers.login.body';
        this.footerHolder = 'rogers.login.footer';
        this.footerRegister = 'rogersShaw.rogers.register.header';
        this.igniteTvRogersAccountLink = 'rogersShaw.rogers.acoountInfo.header';
        this.showIgniteTvRogersAccountLink = true;
      }
      else if (this.igniteUserSelectedBrand === 'shaw'){
        this.signInHeader = 'rogersShaw.shaw.login.header';
        this.headerHolder = 'rogersShaw.shaw.login.body';
        this.footerHolder = 'rogersShaw.shaw.login.footer';
        this.footerRegister = 'rogersShaw.shaw.register.header';
      }
      else if (this.client === 'shaw'){
        this.signInHeader = 'rogersShaw.shaw.login.header';
        this.headerHolder = 'rogersShaw.shaw.login.body';
        this.footerHolder = 'shaw.register.header';
        this.footerRegister = 'shaw.register.link';
      }
      else{
        this.headerHolder = 'igniteTv.accountSelection.loginHeader';
        this.footerHolder = 'rogers.login.footer';
        this.footerRegister = 'igniteTv.register.visit';
        this.footerRegisterHeader = 'igniteTv.register.header';
        this.donotRememberTitle = 'igniteTv.doNotRemember';
      }
    }
    else if (this.igniteTv === 'xfi'){
      this.footerRegister = 'igniteTv.register.visit';
      this.footerRegisterHeader = 'igniteTv.register.header';
      this.donotRememberTitle = 'igniteTv.doNotRemember';
      this.forgotUsernameByEmailDesc = 'rogers.forgotUsername.description.byEmail';
    }
    else {
      this.headerHolder = 'rogers.login.header';
      this.footerHolder = 'rogers.login.footer';
      this.forgotUsernameByEmailDesc = 'rogers.forgotUsername.description.byEmail';
    }
  }

  onBackClick(flow: FlowBranchEnum){

  }

  setAnalyticsError(code: string, flow: FlowBranchEnum) {
    if (!code) {
      return;
    }
    switch (flow) {
      case this.flowBranchEnum.login:
        this.track.usernameInputErrors.category = 'login';
        this.track.usernameInputErrors.mappingKey = 'username-input';
        break;
      case this.flowBranchEnum.username:
        this.track.usernameInputErrors.category = 'self serve';
        this.track.usernameInputErrors.mappingKey = 'find-username-with-email';
        break;
      default:
        this.track.usernameInputErrors.category = 'self serve';
        this.track.usernameInputErrors.mappingKey = 'find-username-with-email';
    }
    this.track.usernameInputErrors.code = code;
    if (code === 'UE05') {
      this.track.usernameInputErrors.pageView = true;
      this.track.usernameInputErrors.mappingKey = 'email-already-registered';
      this.track.usernameInputErrors.type = 'user';
    } else if (code === 'SE01') {
      this.track.usernameInputErrors.type = 'http';
    } else if (code.indexOf('UE') >= 0) {
      this.track.usernameInputErrors.type = 'user';
    }
  }

  async triggerRiskAction(flow: string, userId: string) {
    await this.userSessionRiskService.triggerAction(flow, userId);
  }

  isloginHintEmail(email: string){
    let isValid = false;
    if  (email !== '' && email !== undefined && email !== undefined){
      isValid = true;
    }
    return isValid;
  }

  getHomeLink(){
    return 'https://www.rogers.com';
  }
}

export class LoginStoreModel {
  flowBranch: FlowBranchEnum;
  username: string;
  errorCode: string;
}

export enum FlowBranchEnum {
  login,
  create,
  password,
  username,
  sso,
  profile,
  changeusername
}
