import { Injectable } from '@angular/core';
import { RiskidSdkConfig } from '@transmitsecurity/ngx-ts-riskid';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class DRSConfigurationService {

    constructor(private readonly cookieService: CookieService) {}

    public getValues(){
        const client = this.cookieService.get('client');
        let serviceUri = 'https://tsapi.rogers.com/risk-collect/';

        if (client === 'rogers' || client === 'cbu'){
            serviceUri = 'https://tsapi.rogers.com/risk-collect/';
        }
        else if (client === 'fido'){
            serviceUri = 'https://tsapi.fido.ca/risk-collect/';
        }
        else if (client === 'media'){
            serviceUri = 'https://tsapi.sportsnet.ca/risk-collect/';
        }
        else if (client === 'chatr' || client === 'cpp'){
            serviceUri = 'https://tsapi.chatrwireless.com/risk-collect/';
        }

        return  {
            clientId: '6r0sa5h5ciawcsz70l3rok37t6j0lzfm',
            serverUrl: serviceUri ,
            onError: (err: Error) => {
                console.log(err);
            }
        } as RiskidSdkConfig;
    }
}